.NavigationSection {
  display: flex;
}

.NavigationLinkContainer {
  background: #1F1F1F;
}

.TabsEditContent {
  width: 301px;
}

.NavigationLinkList {
  width: 301px;
}

.NavigationItemList {
  width: 100%;

  .Link {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: 16px;
    padding: 15px 0 15px 95px;

    &.active {
      background: #262626;
      border-right: 4px solid red;
    }
  }
}