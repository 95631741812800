.TabContent {
  width: 316px;
  padding: 0 20px 18px;
}

.ParametersItem {
  margin-bottom: 14px;
}

.DoubleSelect {
  display: flex;
  justify-content: space-between;

  .List {
    width: 48%;
  }
}