.TabContent {
  width: 316px;
  padding: 0 20px 18px;
}

.Section {
  margin-bottom: 24px;

  &__hide {
    display: none;
  }

  .SelectList {
    position: relative;
    z-index: 9;
  }


  .InputField {
    display: inline-block;
    width: 100%;
    max-width: 280px;
    padding: 13px 15px;
    font-size: 15px;
    border-radius: 10px;
    border: 1px solid #363738;
    outline: none;
    background: #262626;
    color: #fff;
  }

  .CustomTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    max-width: 200px;
    margin-bottom: 14px;
  }

  .DownloadIconApp {
    width: 133px;
    height: 133px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 5px 15px;
    border: 2px dashed #FF2A00;
    border-radius: 3px;
    cursor: pointer;
    position: relative;

    &_Icon {
      width: 45px;
      height: 45px;
      object-fit: contain;
    }

    &_TextBlock {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &_Span {
        color: #878787;
        font-size: 12px;
        line-height: 14px;
      }
    }

    &_IconButtonDowload {
      border: 1px solid yellow;
      display: inline-block;
      width: 133px;
      height: 133px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 3;
    }
  }

  .SelectIconBlock {
    display: flex;
  }

  .DownloadIconPreview {
    width: 133px;
    height: 133px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .DownloadMultiScreen {
    position: relative;
    width: 100%;

    &_Preview {

    }

    &_MultiImageButtonDowload {
      display: inline-block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 2;
      border: 1px solid yellow;
    }
  }

  .PreviewMulti {
    display: flex;
    flex-wrap: wrap;
    &_Image {
      display: inline-block;
      width: 133px;
      height: 133px;
      object-fit: contain;
    }
  }

  .ScreenshotList {
    display: flex;
    flex-wrap: wrap;

    .ScreenItem {
      width: 100%;
      max-width: 45%;

      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 10px;
      }

      &:nth-child(odd) {
        margin-right: 10px;
      }
    }
  }
}

