.MainSection {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  display: flex;
  justify-content: space-between;

  &_NavigationEditSection {
    width: 100%;
    max-width: 50%;
  }
}

.PreviewSection {
  width: 50%;
  background: #1F1F1F;
  display: flex;
  justify-content: center;
}