.pwa_container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 17px 0 50px;
    background: #fff;
}

.top {
    margin: 0 22px 21px;
    position: relative;
}

.top__line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 38px;
}

.top__line_left {
    width: 14px;
    height: 14px;
}

.top__line_left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.top__line_right {
    display: flex;
}

.zoom {
    width: 16px;
    height: 16px;
    margin-right: 34px;
}

.zoom img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.menu {
    width: 4px;
    height: 16px;
}

.menu img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title {
    display: flex;
    padding-left: 10px;
    margin-bottom: 20px;
}

.title__avatar {
    width: 74px;
    height: 74px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title__avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main_name {
    color: #282828;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 5px;
}

.name_down {
    color: #0d7e2d;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    text-transform: capitalize;
    margin-bottom: 5px;
}
/* Стилі до блоку "info_line" (початок) */
.info_line {
    display: flex;
    align-items: flex-end;
    margin-bottom: 17px;
    position: relative;
    overflow-x: auto;
}

.infoElement {
    width: 33%;
    min-width: 110px;
    border-right: 2px solid #ddd;
    margin-bottom: 10px;
}

.infoElement:last-child {
    border-right: none;
}

.info_line__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.info_line__left p:nth-child(1) {
    color: #282828;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;

    margin-bottom: 8px;
}

.info_line__left p:nth-child(2) {
    color: #3E3E3E;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
}

.info_line__center {
    display: flex;
    align-items: center;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 120px;
    position: relative;
}

.center p {
    color: #3E3E3E;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
}

.center__icon {
    margin-bottom: 3px;
    width: 20px;
    height: 20px;
}

.center__icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 0 6px;
}

.info_line__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.info_line__right p:nth-child(1) {
    color: #282828;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;

    margin-bottom: 8px;
}

.info_line__right p:nth-child(2) {
    color: #3E3E3E;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
}
/* кінець */

.btn-container {
    position: relative;
    min-height: 37px;
}

/*.firstButton {*/
/*  display: none;*/
/*}*/

.btn {
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 39px;
    background: #0D57CF;
    cursor: pointer;
}

.btn__link {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
    line-height: 37px;
}

.scroll_1 {
    margin-left: 12px;
    margin-bottom: 0;
}

.scroll__container_1 {
    display: flex;
    width: 100%;
    height: 195px;
    overflow-y: hidden;
    padding-left: 10px;
    overflow-x: scroll;
}

.scroll__container_1::-webkit-scrollbar {
    display: none;
}

.scroll__container_1:last-child {
    padding-right: 12px;
}

.item_1 {
    margin-right: 10px;
}

.item_1 img {
    width: 90px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
    object-fit: contain;
    border-radius: 10px;
}

.description {
    margin: 0 22px 15px;
}

.description h1 {
    color: #282828;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;

    margin-bottom: 15px;
}

.description p {
    color: #282828;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    white-space: pre-wrap;
    margin-bottom: 15px;
}

.description ol {
    margin: 0;
    padding-left: 20px;
}
.description ol li{
    color: #282828;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
}

.instruction {
    margin: 0 22px 27px;
}
.instruction h1 {
    color: #282828;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;

    margin-bottom: 22px;
}

.instruction p {
    color: #282828;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
}

.scroll_2 {
    margin-left: 12px;
    margin-bottom: 20px;
}

.scroll__container_2 {
    display: flex;
    width: 100%;
    height: 195px;
    overflow-y: hidden;
    padding-left: 10px;
    overflow-x: scroll;
}

.scroll__container_2::-webkit-scrollbar {
    display: none;
}

.scroll__container_2:last-child {
    padding-right: 5px;
}

.item_2 {
    margin-right: 2px;
}

.item_2 img {
    width: 100px;
    object-fit: cover;
    object-position: center;
    border-radius: 7px;
}

.data_security {
    margin: 0 22px 27px;
}

.data_security__content {
    padding: 26px;
    border-radius: 7px;
    border: 1px solid #C8C8C8;;
}

.data_security h1 {
    color: #282828;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;

    margin-bottom: 22px;
}

.data_security__content p {
    color: #484848;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;

    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.data_security__content p:last-child {
    margin-bottom: 0;
}

.data_security p img {
    margin-right: 15px;
}

.rewievs {
    margin: 0 22px 37px;
}

.rewievs h1 {
    color: #282828;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;

    margin-bottom: 22px;
}

.rewievs__content {
    color: #282828;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;

    margin-bottom: 20px;
}

.rating {
    display: flex;
    margin-bottom: 15px;
}

.rating__left {
    color: #282828;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;

    display: flex;
    align-items: center;
    margin-right: 22px;
}

.rating__right {
    width: 80%;
}

.rating__item {
    display: flex;
    height: 11px;
    margin-bottom: 6px;
}

.item__number {
    color: #282828;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;

    margin-right: 15px;
}

.item__img {
    display: flex;
    width: 100%;
    border-radius: 21px;
    background: #D9D9D9;
    background-size: contain;
}

.item__img img {
    object-fit: contain;
}

.rating__item:last-child {
    margin-bottom: 0;
}

.stars {
    display: flex;
    margin-bottom: 6px;
}

.star__img {
    margin-right: 4px;
}

.stars__content {
    color: #282828;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
}

/* Змінені стилі до секції "comments" та "footer"(початок) */

.comments {
    margin: 0 22px;
}

.comment {
    margin-bottom: 23px;
}

.comment__top {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}

.comment__icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #425963;
    margin-right: 13px;

    text-align: center;
    line-height: 30px;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.comment__title {
    color: #282828;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
}

.comment__stars {
    display: flex;
    margin-bottom: 12px;
}

.comment__content p {
    color: #282828;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
}

.footer {
    margin: 20px 0 0;
}

.footer__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question {
    color: #282828;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
}

.question__btn {
    display: flex;
}

.btn__yes {
    width: 44px;
    height: 29px;
    border: 1px solid #75747a;
    border-radius: 7px;
    margin-right: 8px;

    color: #282828;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
}
.btn__no {
    width: 44px;
    height: 29px;
    border: 1px solid #75747a;
    border-radius: 7px;

    color: #282828;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
}
/* кінець */

.popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.50);
    z-index: 1;
}

.popup__container {
    width: 70%;
    max-width: 250px;
    height: 80%;
    max-height: 500px;
    position: relative;
}

.iconClose {
    position: absolute;
    top: -30px;
    right: -10px;
    width: 30px;
    height: 30px;
}

.iconClose img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.popup__content {
    width: 100%;
    height: 100%;
}

.popup__content img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: contain;
    object-position: top;
}

.loader {
    position: absolute;
    width: 37px;
    height: 37px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    transform: translate(-50%, 0);
    width: 30px;
    height: 30px;
    margin: 2px;
    border: 2px solid #F96703;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.loader div:nth-child(1) {
    animation-delay: -0.45s;
}

.loader div:nth-child(2) {
    animation-delay: -0.3s;
}

.loader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.preloader {
    display: block;
    position: absolute;
    top: 55px;
    left: 10px;
    transform: none;
    width: 74px;
    height: 74px;
}

.counter {
    display: none;
    align-items: center;
    color: #282828;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
}

.counter p {
    margin-left: 3px;
}


.reviews_container {}

.js_first_button,
.firstButton,
.loader_item{}