.TabContent {
  width: 316px;
  padding: 0 20px 18px;
}

.CountReview {
  margin-bottom: 24px;

  &_ListRadio {
    display: flex;
    justify-content: space-between;
  }

  &_Radio {

    label {

    }
  }
}

.TemplateReviews {
  margin-bottom: 24px;

  &_CheckboxTemplate {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &_Text {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  &_Languages {

  }
}

.ReviewsBlock {

}

.ReviewBlockItem {
  margin-bottom: 15px;

  label {
    display: inline-block;
    margin-bottom: 6px;
    color: #878787;
    font-size: 14px;
    font-weight: 400;
  }

  &_TextTemplateReview {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.3;
  }
}


.InputField {
  display: inline-block;
  width: 100%;
  max-width: 280px;
  padding: 13px 15px;
  font-size: 15px;
  border-radius: 10px;
  border: 1px solid #363738;
  outline: none;
  background: #262626;
  color: #fff;
}
