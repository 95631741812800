.InformationText {
  margin-bottom: 10px;
  position: relative;

  .TitleInformation {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  &_Icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  &_Text {
    font-size: 14px;
    line-height: 16px;
    color: #878787;
    padding: 0 0 0 10px;
  }

  .Description {

    background: #1f1f1f;
    padding: 24px;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #363738;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    z-index: 9;
  }
}