.PreviewContainer {
  width: 100%;
  max-width: 390px;
}

.HeaderPreview {
  display: flex;
  justify-content: center;
}

.InfoDownload {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

   &_NameLand {
     width: 100%;
     max-width: 283px;
     padding: 0 10px;
     background: #262626;
     border-bottom-left-radius: 10px;
     border-bottom-right-radius: 10px;
     margin-right: 10px;

     &_Text {
       text-align: center;
       font-size: 20px;
       line-height: 23px;
       font-weight: normal;
       padding: 14px 0;
       overflow: hidden;
       white-space: nowrap;
       text-overflow: ellipsis;
     }
   }

  &_SendLand {
    width: max-content;
    height: 51px;
    background: #262626;
    padding: 0 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;

    &_Button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }
    }
  }
}